import * as React from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { useReportStyles } from './styles'

export type PTMBPSectionProps = {
    currentStrategyStateGap: number
    currentCultureStateGap: number
    currentDataStateGap: number
    currentStructureStateGap: number
}

export const PTMBPSection = ({
    currentStrategyStateGap,
    currentCultureStateGap,
    currentDataStateGap,
    currentStructureStateGap,
}: PTMBPSectionProps) => {
    const { t } = useTranslation()
    const styles = useReportStyles()

    const potentialDescriptionByGap = (gap: number) => {
        return gap > 75
            ? 'development_state.dormant'
            : gap > 50
            ? 'development_state.earlyDevelopment'
            : gap > 30
            ? 'development_state.lateDevelopment'
            : gap > 15
            ? 'development_state.readyForNextLevel'
            : 'development_state.steady'
    }

    const devStates = [
        { cluster: 'cluster.strategy', devStateGap: currentStrategyStateGap },
        { cluster: 'cluster.culture', devStateGap: currentCultureStateGap },
        { cluster: 'cluster.data', devStateGap: currentDataStateGap },
        { cluster: 'cluster.structure', devStateGap: currentStructureStateGap },
    ]
    devStates.sort((a, b) => {
        return b.devStateGap - a.devStateGap
    })
    return (
        <Box className={styles.sectionContainer}>
            <Box className={styles.sectionTitle}>
                {t('ptmbp.title', { ns: 'report' })}
            </Box>
            <Box className={styles.sectionTextContainer}>
                <Box className={styles.sectionText}>
                    {t('ptmbp.text.top', {
                        data: t(
                            potentialDescriptionByGap(currentDataStateGap),
                            { ns: 'report' }
                        ),
                        culture: t(
                            potentialDescriptionByGap(currentCultureStateGap),
                            { ns: 'report' }
                        ),
                        strategy: t(
                            potentialDescriptionByGap(currentStrategyStateGap),
                            { ns: 'report' }
                        ),
                        structure: t(
                            potentialDescriptionByGap(currentStructureStateGap),
                            { ns: 'report' }
                        ),
                        ns: 'report',
                    })}
                    <br />
                    <br />
                    {devStates[0].devStateGap - devStates[3].devStateGap >= 5
                        ? t('ptmbp.text.SC1', {
                              cluster1: t(devStates[0].cluster, {
                                  ns: 'report',
                              }),
                              cluster2: t(devStates[3].cluster, {
                                  ns: 'report',
                              }),
                              cluster1gap: devStates[0].devStateGap.toFixed(1),
                              cluster2gap: devStates[3].devStateGap.toFixed(1),
                              ns: 'report',
                          })
                        : t('ptmbp.text.SC2', { ns: 'report' })}
                </Box>

                <Box className={styles.sectionQtc}>
                    {devStates[0].devStateGap - devStates[3].devStateGap >= 5
                        ? t('ptmbp.qtc.SC1', { ns: 'report' })
                        : t('ptmbp.qtc.SC2', { ns: 'report' })}
                </Box>
            </Box>
        </Box>
    )
}
