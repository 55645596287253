import * as React from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { useReportStyles } from './styles'
import { Grid } from '@material-ui/core'
import blank from '../../Assets/blank.png'
import dashed from '../../Assets/dashed.png'
import full from '../../Assets/full.png'
import vmv from '../../Assets/vmv.png'
import smallCircle from '../../Assets/smallCircle.png'
import bigCircle from '../../Assets/bigCircle.png'

export type GraphProps = {
    graphPath: string
}

export const Graph = ({ graphPath }: GraphProps) => {
    const { t } = useTranslation()
    const styles = useReportStyles()
    return (
        <Box sx={{ width: '60%' }} className={styles.generalContainer}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ width: '100%' }}>
                    <img
                        src={`https://ocs-diagnostics-graphs.s3.eu-west-3.amazonaws.com/${graphPath}`}
                        alt="graph"
                        className={styles.boxImage}
                    />
                </Box>
                <Box sx={{ width: '75%', alignSelf: 'center' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Box className={styles.legendItem}>
                                <img
                                    src={blank}
                                    alt="blank"
                                    className={styles.legendItemIcon}
                                />
                                <Box className={styles.legendItemLabel}>
                                    {t('notAlignedWithVmv')}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box className={styles.legendItem}>
                                <Box>
                                    <img
                                        src={vmv}
                                        alt="red dot"
                                        className={styles.legendItemIcon}
                                    />
                                </Box>
                                <Box className={styles.legendItemLabel}>
                                    {t('vmv')}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box className={styles.legendItem}>
                                <Box>
                                    <img
                                        src={dashed}
                                        alt="blank"
                                        className={styles.legendItemIcon}
                                    />
                                </Box>
                                <Box className={styles.legendItemLabel}>
                                    {t('partiallyAlignedWithVmv')}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box className={styles.legendItem}>
                                <Box>
                                    <img
                                        src={smallCircle}
                                        alt="small circle"
                                        className={styles.legendItemIcon}
                                    />
                                </Box>
                                <Box className={styles.legendItemLabel}>
                                    {t('innerCircle')}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box className={styles.legendItem}>
                                <Box>
                                    <img
                                        src={full}
                                        alt="full"
                                        className={styles.legendItemIcon}
                                    />
                                </Box>
                                <Box className={styles.legendItemLabel}>
                                    {t('fullyAlignedWithVmv')}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box className={styles.legendItem}>
                                <Box>
                                    <img
                                        src={bigCircle}
                                        alt="blank"
                                        className={styles.legendItemIcon}
                                    />
                                </Box>
                                <Box className={styles.legendItemLabel}>
                                    {t('outerCircle')}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}
