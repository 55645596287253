import * as React from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { useReportStyles } from './styles'
import { q2Answers } from '../../Constants/questions'

export type RecommendationsSectionProps = {
    Q2: string
}

export const RecommendationsSection = ({ Q2 }: RecommendationsSectionProps) => {
    const { t } = useTranslation()
    const styles = useReportStyles()
    return (
        <Box className={styles.recommendationsTextContainer}>
            {t('recommendations.text.top', { ns: 'report' })}
            <ul>
                {Q2 === q2Answers[1].value && (
                    <li>
                        {t('recommendations.text.continuous.l1', {
                            ns: 'report',
                        })}
                    </li>
                )}
                <li>{t('recommendations.text.l1', { ns: 'report' })}</li>
                <li>{t('recommendations.text.l2', { ns: 'report' })}</li>
                <li>{t('recommendations.text.l3', { ns: 'report' })}</li>
                <li>{t('recommendations.text.l4', { ns: 'report' })}</li>
            </ul>
            {t('recommendations.text.finally', { ns: 'report' })}
        </Box>
    )
}
