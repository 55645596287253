import { makeStyles, Theme } from '@material-ui/core/styles'

export const useFormStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
        width: '96vw',
        maxWidth: '1000px',
        background: '#FFFFFF',
        marginLeft: 'auto',
        marginRight: 'auto',
        overflow: 'hidden',
        position: 'relative',
    },

    reportMask: {
        overflow: 'hidden',
        position: 'absolute',
        height: '0.1px',
        opacity: 0,
        pointerEvents: 'none',
    },

    reportPageMask: {},

    header: {
        alignSelf: 'center',
        width: '100%',
        background: '#45454511',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },

    headerContainer: {
        fontSize: '17px',
        alignSelf: 'center',
        width: '80%',
        color: '#454545',
    },

    formContainer: {
        display: 'flex',
        width: '90%',
        flexDirection: 'column',
        alignSelf: 'center',
        height: '100%',
        marginTop: '20px',
        paddingBottom: '40px',
    },

    footerContainer: {
        padding: '40px',
        fontSize: '15px',
        width: '100%',
        marginTop: 'auto',
        backgroundColor: '#0054a3',
        color: 'white',
    },

    footerName: {
        color: '#F07400',
        textDecoration: 'none',
    },

    callToActionButtonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
        margin: '25px',
    },

    callToActionButton: {
        margin: '15px',
        color: '#0054a3',
        borderColor: '#0054a3',
    },

    label: {
        fontSize: '20px',
        margin: '10px',
    },

    question: {
        margin: '20px',
        padding: '10px',
        border: '1px solid #d9d7d7',
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#fefefe',
    },
    submit: {
        margin: '20px',
        padding: '10px',
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#fefefe',
    },

    q1Select: {
        width: '25%',
    },

    generateButton: {
        width: '50%',
        alignSelf: 'center',
    },

    sendEmailTextField: {
        alignSelf: 'center',
        margin: '5px',
    },

    sendEmailButton: {
        width: '30%',
        alignSelf: 'center',
        margin: '5px',
    },

    graph: {
        maxWidth: '100%',
    },

    loader: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '35px',
    },
}))
