import {
    Box,
    FormControlLabel,
    FormLabel,
    Input,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
} from '@material-ui/core'
import React from 'react'
import { useReportStyles } from './styles'
import { q2Answers, q3Answers, q4Answers } from '../../Constants/questions'
import { useTranslation } from 'react-i18next'
import { CustomSlider } from '../CustomSlider'

export type OriginalInputSectionProps = {
    QName: string
    Q1: string
    Q2: string
    Q3: string
    Q4: string
    Q5: number
    Q6: number
    Q7: number
    Q8: number
    Q9: number
    Q10: number
    Q11: number
    Q12: number
    Q13: number
    Q14: number
    Q15: number
    Q16: number
    QEvents: number
}

export const OriginalInputSection = ({
    QName,
    Q1,
    Q2,
    Q3,
    Q4,
    Q5,
    Q6,
    Q7,
    Q8,
    Q9,
    Q10,
    Q11,
    Q12,
    Q13,
    Q14,
    Q15,
    Q16,
    QEvents,
}: OriginalInputSectionProps) => {
    const { t } = useTranslation()
    const styles = useReportStyles()
    return (
        <Box className={styles.originalInput}>
            <Box className={styles.originalInputLeft}>
                <Box id="reportboxq1" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question1')}
                    </FormLabel>
                    <Select
                        id="reportq1"
                        value={Q1}
                        className={styles.q1Select}
                    >
                        <MenuItem value={'No'}>{t('No')}</MenuItem>
                        <MenuItem value={'Yes'}>{t('Yes')}</MenuItem>
                    </Select>
                </Box>
                {Q1 === 'Yes' && (
                    <Box>
                        <Box id="reportboxq2" className={styles.question}>
                            <FormLabel className={styles.label}>
                                {t('question2')}
                            </FormLabel>
                            <RadioGroup id="reportq2RadioGroup" value={Q2}>
                                {q2Answers.map((answer, index) => {
                                    return (
                                        <FormControlLabel
                                            value={answer.value}
                                            control={
                                                <Radio
                                                    color="primary"
                                                    size="small"
                                                />
                                            }
                                            label={
                                                <Box
                                                    className={
                                                        styles.radioLabel
                                                    }
                                                >
                                                    {t(answer.value)}
                                                </Box>
                                            }
                                        />
                                    )
                                })}
                            </RadioGroup>
                        </Box>
                        <Box id="reportboxq3" className={styles.question}>
                            <FormLabel className={styles.label}>
                                {t('question3')}
                            </FormLabel>
                            <RadioGroup id="reportq3RadioGroup" value={Q3}>
                                {q3Answers.map((answer) => {
                                    return (
                                        <FormControlLabel
                                            value={answer.value}
                                            control={
                                                <Radio
                                                    color="primary"
                                                    size="small"
                                                />
                                            }
                                            label={
                                                <Box
                                                    className={
                                                        styles.radioLabel
                                                    }
                                                >
                                                    {t(answer.value)}
                                                </Box>
                                            }
                                        />
                                    )
                                })}
                            </RadioGroup>
                        </Box>
                        <Box id="reportboxq4" className={styles.question}>
                            <FormLabel className={styles.label}>
                                {t('question4')}
                            </FormLabel>
                            <RadioGroup id="reportq4RadioGroup" value={Q4}>
                                {q4Answers.map((answer) => {
                                    return (
                                        <FormControlLabel
                                            value={answer.value}
                                            control={
                                                <Radio
                                                    color="primary"
                                                    size="small"
                                                />
                                            }
                                            label={
                                                <Box
                                                    className={
                                                        styles.radioLabel
                                                    }
                                                >
                                                    {t(answer.value)}
                                                </Box>
                                            }
                                        />
                                    )
                                })}
                            </RadioGroup>
                        </Box>

                        {Q2 === q2Answers[1].value && (
                            <Box id="reportqEvents" className={styles.question}>
                                <FormLabel className={styles.label}>
                                    {t('questionEvents')}
                                </FormLabel>
                                <Input
                                    type="number"
                                    id="reportqEventsInput"
                                    value={QEvents}
                                />
                            </Box>
                        )}
                    </Box>
                )}
                <Box id="reportboxq5" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question5')}
                    </FormLabel>
                    <CustomSlider
                        id="reportq5"
                        value={Q5}
                        setValue={() => {}}
                        customWidth={'95%'}
                        smallSize
                    />
                </Box>
                <Box id="reportboxq6" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question6')}
                    </FormLabel>
                    <CustomSlider
                        id="reportq6"
                        value={Q6}
                        setValue={() => {}}
                        customWidth={'95%'}
                        smallSize
                    />
                </Box>
                <Box id="reportboxq7" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question7')}
                    </FormLabel>
                    <CustomSlider
                        id="reportq7"
                        value={Q7}
                        setValue={() => {}}
                        customWidth={'95%'}
                        smallSize
                    />
                </Box>
            </Box>
            <Box className={styles.originalInputRight}>
                <Box id="reportboxq8" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question8')}
                    </FormLabel>
                    <CustomSlider
                        id="reportq8"
                        value={Q8}
                        setValue={() => {}}
                        customWidth={'95%'}
                        smallSize
                    />
                </Box>
                <Box id="reportboxq9" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question9')}
                    </FormLabel>
                    <CustomSlider
                        id="reportq9"
                        value={Q9}
                        setValue={() => {}}
                        customWidth={'95%'}
                        smallSize
                    />
                </Box>
                <Box id="reportboxq10" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question10')}
                    </FormLabel>
                    <CustomSlider
                        id="reportq10"
                        value={Q10}
                        setValue={() => {}}
                        customWidth={'95%'}
                        smallSize
                    />
                </Box>
                <Box id="reportboxq11" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question11')}
                    </FormLabel>
                    <CustomSlider
                        id="reportq11"
                        value={Q11}
                        setValue={() => {}}
                        customWidth={'95%'}
                        smallSize
                    />
                </Box>
                <Box id="reportboxq12" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question12')}
                    </FormLabel>
                    <CustomSlider
                        id="reportq12"
                        value={Q12}
                        setValue={() => {}}
                        customWidth={'95%'}
                        smallSize
                    />
                </Box>
                <Box id="reportreportboxq13" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question13')}
                    </FormLabel>
                    <CustomSlider
                        id="reportq13"
                        value={Q13}
                        setValue={() => {}}
                        customWidth={'95%'}
                        smallSize
                    />
                </Box>
                <Box id="reportboxq14" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question14')}
                    </FormLabel>
                    <CustomSlider
                        id="reportq14"
                        value={Q14}
                        setValue={() => {}}
                        customWidth={'95%'}
                        smallSize
                    />
                </Box>
                <Box id="reportboxq15" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question15')}
                    </FormLabel>
                    <CustomSlider
                        id="reportq15"
                        value={Q15}
                        setValue={() => {}}
                        customWidth={'95%'}
                        smallSize
                    />
                </Box>
                <Box id="reportboxq16" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question16')}
                    </FormLabel>
                    <CustomSlider
                        id="reportq16"
                        value={Q16}
                        setValue={() => {}}
                        customWidth={'95%'}
                        smallSize
                    />
                </Box>
            </Box>
        </Box>
    )
}
