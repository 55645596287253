import * as React from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { useReportStyles } from './styles'

export type AlignmentSectionProps = {
    Q1: string
    Q3: string
    Q4: string
    vmvSector: string
    vmvPosition: string
}

export const AlignmentSection = ({
    Q1,
    Q3,
    Q4,
    vmvSector,
    vmvPosition,
}: AlignmentSectionProps) => {
    const { t } = useTranslation()
    const styles = useReportStyles()
    return (
        <Box className={styles.sectionContainer}>
            <Box className={styles.sectionTitle}>
                {t('alignment.title', { ns: 'report' })}
            </Box>
            <Box className={styles.sectionTextContainer}>
                <Box className={styles.sectionText}>
                    {Q1 === 'Yes'
                        ? Q3 === 'ClearAndSupportive' && Q4 === 'VeryLikely'
                            ? t('alignment.vmvCentered.text', { ns: 'report' })
                            : vmvSector === 'Data'
                            ? t('alignment.vmvSkewed.text.data', {
                                  ns: 'report',
                              })
                            : vmvSector === 'Structure'
                            ? t('alignment.vmvSkewed.text.structure', {
                                  ns: 'report',
                              })
                            : vmvSector === 'Culture'
                            ? t('alignment.vmvSkewed.text.culture', {
                                  ns: 'report',
                              })
                            : t('alignment.vmvSkewed.text.strategy', {
                                  ns: 'report',
                              })
                        : t('alignment.noVmv.text', { ns: 'report' })}
                </Box>
                <Box>
                    {Q1 === 'Yes'
                        ? Q3 === 'ClearAndSupportive' && Q4 === 'VeryLikely'
                            ? t('alignment.vmvCentered.qtc', { ns: 'report' })
                            : t('alignment.vmvSkewed.qtc', { ns: 'report' })
                        : t('alignment.noVmv.qtc', { ns: 'report' })}
                </Box>
            </Box>
        </Box>
    )
}
