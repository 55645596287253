import * as React from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { useReportStyles } from './styles'
import { q2Answers } from '../../Constants/questions'

export type CSOTBPSectionProps = {
    Q2: string
    currentState: number
    currentStrategyState: number
    currentCultureState: number
    currentDataState: number
    currentStructureState: number
}

export const CSOTBPSection = ({
    Q2,
    currentState,
    currentStrategyState,
    currentCultureState,
    currentDataState,
    currentStructureState,
}: CSOTBPSectionProps) => {
    const { t } = useTranslation()
    const styles = useReportStyles()
    const developmentState =
        currentState <= 24
            ? 'development_state.dormant'
            : currentState <= 49
            ? 'development_state.earlyDevelopment'
            : currentState <= 69
            ? 'development_state.lateDevelopment'
            : currentState <= 85
            ? 'development_state.readyForNextLevel'
            : 'development_state.steady'
    const devStates = [
        { cluster: 'cluster.strategy', devState: currentStrategyState },
        { cluster: 'cluster.culture', devState: currentCultureState },
        { cluster: 'cluster.data', devState: currentDataState },
        { cluster: 'cluster.structure', devState: currentStructureState },
    ]
    const under15 = devStates.filter((s) => s.devState < 15)
    const between16and24 = devStates.filter(
        (s) => s.devState >= 15 && s.devState < 25
    )
    const between25and39 = devStates.filter(
        (s) => s.devState >= 25 && s.devState < 40
    )
    const over40 = devStates.filter((s) => s.devState >= 40)
    return (
        <Box className={styles.sectionContainer}>
            <Box className={styles.sectionTitle}>
                {t('csotbp.title', { ns: 'report' })}
            </Box>
            <Box className={styles.sectionTextContainer}>
                {Q2 === q2Answers[0].value && (
                    <Box>
                        <Box className={styles.sectionText}>
                            {t('csotbp.text', {
                                development_state: t(developmentState, {
                                    ns: 'report',
                                }),
                                potential: currentState.toFixed(1),
                                ns: 'report',
                            })}
                            <br />
                            <br />
                            {under15.length === 1 ? (
                                <Box>
                                    {t('csotbp.under_15.one_cluster', {
                                        cluster: t(under15[0].cluster, {
                                            ns: 'report',
                                        }),
                                        ns: 'report',
                                    })}
                                    <br />
                                    <br />
                                </Box>
                            ) : under15.length === 2 ? (
                                <Box>
                                    {t('csotbp.under_15.two_cluster', {
                                        cluster1: t(under15[0].cluster, {
                                            ns: 'report',
                                        }),
                                        cluster2: t(under15[1].cluster, {
                                            ns: 'report',
                                        }),
                                        ns: 'report',
                                    })}
                                    <br />
                                    <br />
                                </Box>
                            ) : under15.length === 3 ? (
                                <Box>
                                    {t('csotbp.under_15.three_cluster', {
                                        cluster1: t(under15[0].cluster, {
                                            ns: 'report',
                                        }),
                                        cluster2: t(under15[1].cluster, {
                                            ns: 'report',
                                        }),
                                        cluster3: t(under15[2].cluster, {
                                            ns: 'report',
                                        }),
                                        ns: 'report',
                                    })}
                                    <br />
                                    <br />
                                </Box>
                            ) : (
                                <></>
                            )}
                            {between16and24.length === 1 ? (
                                <Box>
                                    {t('csotbp.16_24.one_cluster', {
                                        cluster: t(between16and24[0].cluster, {
                                            ns: 'report',
                                        }),
                                        ns: 'report',
                                    })}
                                    <br />
                                    <br />
                                </Box>
                            ) : between16and24.length === 2 ? (
                                <Box>
                                    {t('csotbp.16_24.two_cluster', {
                                        cluster1: t(between16and24[0].cluster, {
                                            ns: 'report',
                                        }),
                                        cluster2: t(between16and24[1].cluster, {
                                            ns: 'report',
                                        }),
                                        ns: 'report',
                                    })}
                                    <br />
                                    <br />
                                </Box>
                            ) : between16and24.length === 3 ? (
                                <Box>
                                    {t('csotbp.16_24.three_cluster', {
                                        cluster1: t(between16and24[0].cluster, {
                                            ns: 'report',
                                        }),
                                        cluster2: t(between16and24[1].cluster, {
                                            ns: 'report',
                                        }),
                                        cluster3: t(between16and24[2].cluster, {
                                            ns: 'report',
                                        }),
                                        ns: 'report',
                                    })}
                                    <br />
                                    <br />
                                </Box>
                            ) : (
                                <></>
                            )}
                            {between25and39.length === 1 ? (
                                <Box>
                                    {t('csotbp.25_39.one_cluster', {
                                        cluster1: t(between25and39[0].cluster, {
                                            ns: 'report',
                                        }),
                                        ns: 'report',
                                    })}
                                    <br />
                                    <br />
                                </Box>
                            ) : between25and39.length === 2 ? (
                                <Box>
                                    {t('csotbp.25_39.two_cluster', {
                                        cluster1: t(between25and39[0].cluster, {
                                            ns: 'report',
                                        }),
                                        cluster2: t(between25and39[1].cluster, {
                                            ns: 'report',
                                        }),
                                        ns: 'report',
                                    })}
                                    <br />
                                    <br />
                                </Box>
                            ) : between25and39.length === 3 ? (
                                <Box>
                                    {t('csotbp.25_39.three_cluster', {
                                        cluster1: t(between25and39[0].cluster, {
                                            ns: 'report',
                                        }),
                                        cluster2: t(between25and39[1].cluster, {
                                            ns: 'report',
                                        }),
                                        cluster3: t(between25and39[2].cluster, {
                                            ns: 'report',
                                        }),
                                        ns: 'report',
                                    })}
                                    <br />
                                    <br />
                                </Box>
                            ) : between25and39.length === 4 ? (
                                <Box>
                                    {t('csotbp.25_39.four_cluster', {
                                        cluster1: t(between25and39[0].cluster, {
                                            ns: 'report',
                                        }),
                                        cluster2: t(between25and39[1].cluster, {
                                            ns: 'report',
                                        }),
                                        cluster3: t(between25and39[2].cluster, {
                                            ns: 'report',
                                        }),
                                        cluster4: t(between25and39[3].cluster, {
                                            ns: 'report',
                                        }),
                                        ns: 'report',
                                    })}
                                    <br />
                                    <br />
                                </Box>
                            ) : (
                                <></>
                            )}
                            {over40.length === 1 ? (
                                <Box>
                                    {t('csotbp.over_40.one_cluster', {
                                        cluster1: t(over40[0].cluster, {
                                            ns: 'report',
                                        }),
                                        ns: 'report',
                                    })}
                                    <br />
                                    <br />
                                </Box>
                            ) : over40.length === 2 ? (
                                <Box>
                                    {t('csotbp.over_40.two_cluster', {
                                        cluster1: t(over40[0].cluster, {
                                            ns: 'report',
                                        }),
                                        cluster2: t(over40[1].cluster, {
                                            ns: 'report',
                                        }),
                                        ns: 'report',
                                    })}
                                    <br />
                                    <br />
                                </Box>
                            ) : (
                                <></>
                            )}
                        </Box>
                        <Box className={styles.sectionQtc}>
                            {t('csotbp.qtc', { ns: 'report' })}
                        </Box>
                    </Box>
                )}
                {Q2 === q2Answers[1].value && (
                    <Box className={styles.sectionQtc}>
                        {t('csobp.continuously.text', { ns: 'report' })}
                    </Box>
                )}
            </Box>
        </Box>
    )
}
