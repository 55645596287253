import { makeStyles, Theme } from '@material-ui/core/styles'

export const useReportStyles = makeStyles((theme: Theme) => ({
    reportPage: {
        backgroundColor: '#0054a3',
        width: '2480px',
        height: '3508px',
        padding: '60px',
    },

    reportPageContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundColor: '#ffffff',
    },

    reportInnerContainer: {
        display: 'flex',
        marginTop: '50px',
        flexDirection: 'row',
        height: '90%',
        width: '95%',
        alignSelf: 'center',
    },

    reportBottomContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '10%',
        alignItems: 'center',
        border: '1px solid #d9d7d7',
    },

    leftColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
        width: '49%',
        height: '100%',
        marginTop: '10px',
    },

    rightColumn: {
        display: 'flex',
        flexDirection: 'column',
        allignItems: 'center',
        alignSelf: 'center',
        width: '49%',
        height: '100%',
        marginTop: '10px',
    },

    middleColumn: {
        width: '2%',
        writingMode: 'vertical-lr',
        fontSize: '20px',
    },

    //LEFT SIDE

    boxImage: {
        maxWidth: '100%',
    },

    generalContainer: {
        margin: '7px',
        padding: '7px',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    questionsToConsider: {
        textAlign: 'start',
    },

    questionsToConsiderItem: {
        margin: '5px',
    },

    legendItem: {
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'start',
        padding: '5px',
    },

    legendItemIcon: {
        height: '60px',
    },

    legendItemLabel: {
        fontSize: '15px',
        margin: '5px',
    },

    graphAnalysisTable: {
        marginTop: '15px',
        fontSize: '45px',
    },

    graphAnalysisTitle: {
        fontSize: '55px',
        margin: '20px',
        fontWeight: 'bold',
    },

    graphAnalysisSubtitle: {
        fontSize: '45px',
        margin: '20px',
    },

    //RIGHT SIDE

    label: {
        fontSize: '30px',
        margin: '10px',
    },

    columnHeader: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: '20px',
        lineHeight: 1.2,
        margin: '8px',
    },

    radioLabel: {
        fontSize: '25px',
        width: '100%',
        margin: '10px',
    },

    q1Select: {
        width: '25%',
        fontSize: '25px',
        margin: '10px',
    },

    question: {
        margin: '7px',
        padding: '7px',
        border: '1px solid #d9d7d7',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#fefefe',
    },

    ///BOTTOM
    callToAction: {
        textDecoration: 'none',
        border: '1px solid #d9d7d7',
        backgroundColor: '#aed5fc',
        borderRadius: '20px',
        fontSize: '45px',
        color: 'black',
        margin: '40px',
    },

    bottomTrademark: {
        fontSize: '25px',
        margin: '20px',
    },

    // SECTION

    sectionContainer: {
        width: '95%',
        display: 'flex',
        flexDirection: 'row',
        margin: '30px',
    },

    sectionTitle: {
        width: '25%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
        fontSize: '60px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: '#0054a3',
    },

    sectionTextContainer: {
        width: '70%',
        display: 'flex',
        flexDirection: 'column',
        borderInlineStart: '1px solid #0054a3',
        fontSize: '40px',
        margin: '30px',
        padding: '30px',
        textAlign: 'justify',
    },

    recommendationsTextContainer: {
        width: '93%',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '45px',
        margin: '50px',
        textAlign: 'justify',
    },

    sectionText: {
        marginBottom: '40px',
    },

    sectionQtc: {},

    originalInput: {
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'center',
        width: '97%',
        height: '95%',
        fontSize: '15px',
    },

    originalInputLeft: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        width: '49%',
        height: '100%',
        marginTop: '10px',
    },

    originalInputRight: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        width: '49%',
        height: '100%',
        marginTop: '10px',
    },

    footerContainer: {
        padding: '15px',
        fontSize: '30px',
        width: '100%',
        marginTop: 'auto',
        backgroundColor: '#0054a3',
        color: 'white',
    },

    footerName: {
        color: '#F07400',
        textDecoration: 'none',
    },
}))
