import React from 'react'
import './App.css'
import { Form } from './Components/Form'
import { useTranslation } from 'react-i18next'

function App() {
    const { i18n } = useTranslation()
    document.body.dir = i18n.dir()
    return (
        <div className="App">
            <Form />
        </div>
    )
}

export default App
