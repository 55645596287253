import * as React from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { useReportStyles } from './styles'

export type FooterProps = {}

export const Footer = () => {
    const { t } = useTranslation()
    React.useEffect(() => {
        document.getElementById('year')!.innerHTML = new Date()
            .getFullYear()
            .toString()
    }, [])
    const styles = useReportStyles()
    return (
        <Box className={styles.footerContainer}>
            <Box>
                (C)<span id="year"></span> {t('AllRightsReserved')}
            </Box>
            <Box>
                {t('builtBy')}{' '}
                <a
                    href="https://www.linkedin.com/in/andrei-tudorica-661a22b9/"
                    className={styles.footerName}
                >
                    Andrei Tudorica
                </a>
            </Box>
        </Box>
    )
}
