import * as React from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

export type SectionHeaderProps = {
    name: string
}

export const SectionHeader = ({ name }: SectionHeaderProps) => {
    return (
        <Box
            sx={{
                width: '100%',
                fontSize: '30px',
                fontWeight: '700',
                color: '#F07400',
                borderBottom: '1px solid #0054a3',
                borderTop: '1px solid #0054a3',
                backgroundColor: '#d9d7d7',
            }}
        >
            <h1>{name}</h1>
        </Box>
    )
}
