import { Box } from '@material-ui/core'
import React from 'react'
import { useReportStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { ReportHeader } from './ReportHeader'
import { GraphAnalysis } from './GraphAnalisys'
import { Graph } from './Graph'
import { SectionHeader } from './SectionHeader'
import { AlignmentSection } from './AlignmentSection'
import { CoverageSection } from './CoverageSection'
import { BusinessOrientationSection } from './BusinessOrientationSection'
import { CSOTBPSection } from './CSOTBPSection'
import { PTMBPSection } from './PTMBPSection'
import { OriginalInputSection } from './OriginalInputSection'
import { RecommendationsSection } from './RecommendationsSection'
import { Footer } from './Footer'
export type ReportProps = {
    QName: string
    Q1: string
    Q2: string
    Q3: string
    Q4: string
    Q5: number
    Q6: number
    Q7: number
    Q8: number
    Q9: number
    Q10: number
    Q11: number
    Q12: number
    Q13: number
    Q14: number
    Q15: number
    Q16: number
    QEvents: number
    graphPath: string
    smallCircleArea: number
    bigCircleArea: number
    strategyArea: number
    cultureArea: number
    structureArea: number
    dataArea: number
    vmvPosition: string
    vmvSector: string
}
export const ReportPage1 = ({
    QName,
    Q1,
    Q2,
    Q3,
    Q4,
    Q5,
    Q6,
    Q7,
    Q8,
    Q9,
    Q10,
    Q11,
    Q12,
    Q13,
    Q14,
    Q15,
    Q16,
    QEvents,
    graphPath,
    smallCircleArea,
    bigCircleArea,
    strategyArea,
    cultureArea,
    structureArea,
    dataArea,
    vmvPosition,
    vmvSector,
}: ReportProps) => {
    const { t, i18n } = useTranslation()
    document.body.dir = i18n.dir()
    const styles = useReportStyles()
    const now = new Date()
    const minPotential = (smallCircleArea * 100) / bigCircleArea
    const currentState =
        ((strategyArea + cultureArea + structureArea + dataArea) * 100) /
        bigCircleArea
    const currentStrategyState =
        (strategyArea * 100) /
        (strategyArea + cultureArea + structureArea + dataArea)
    const currentCultureState =
        (cultureArea * 100) /
        (strategyArea + cultureArea + structureArea + dataArea)
    const currentStructureState =
        (structureArea * 100) /
        (strategyArea + cultureArea + structureArea + dataArea)
    const currentDataState =
        (dataArea * 100) /
        (strategyArea + cultureArea + structureArea + dataArea)
    const currentStrategyStateGap =
        100 - (strategyArea * 100) / (bigCircleArea / 4)
    const currentCultureStateGap =
        100 - (cultureArea * 100) / (bigCircleArea / 4)
    const currentStructureStateGap =
        100 - (structureArea * 100) / (bigCircleArea / 4)
    const currentDataStateGap = 100 - (dataArea * 100) / (bigCircleArea / 4)

    return (
        <Box className={styles.reportPage}>
            <Box className={styles.reportPageContainer}>
                <ReportHeader date={now.toLocaleDateString()} name={QName} />
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Graph graphPath={graphPath} />
                    <GraphAnalysis
                        {...{
                            Q1,
                            Q3,
                            Q4,
                            vmvSector,
                            vmvPosition,
                            minPotential,
                            currentState,
                            currentStrategyState,
                            currentStrategyStateGap,
                            currentCultureState,
                            currentCultureStateGap,
                            currentDataState,
                            currentDataStateGap,
                            currentStructureState,
                            currentStructureStateGap,
                        }}
                    />
                </Box>
                <SectionHeader name={t('part1Title', { ns: 'report' })} />
                <AlignmentSection
                    {...{
                        Q1,
                        Q3,
                        Q4,
                        vmvSector,
                        vmvPosition,
                    }}
                />
                <CoverageSection
                    {...{
                        Q1,
                        Q2,
                        Q3,
                        Q4,
                        QEvents,
                        vmvSector,
                        vmvPosition,
                    }}
                />
                <Footer />
            </Box>
        </Box>
    )
}
export const ReportPage2 = ({
    QName,
    Q1,
    Q2,
    Q3,
    Q4,
    Q5,
    Q6,
    Q7,
    Q8,
    Q9,
    Q10,
    Q11,
    Q12,
    Q13,
    Q14,
    Q15,
    Q16,
    QEvents,
    graphPath,
    smallCircleArea,
    bigCircleArea,
    strategyArea,
    cultureArea,
    structureArea,
    dataArea,
    vmvPosition,
    vmvSector,
}: ReportProps) => {
    const { t, i18n } = useTranslation()
    document.body.dir = i18n.dir()
    const styles = useReportStyles()
    const now = new Date()
    const minPotential = (smallCircleArea * 100) / bigCircleArea
    const currentState =
        ((strategyArea + cultureArea + structureArea + dataArea) * 100) /
        bigCircleArea
    const currentStrategyState =
        (strategyArea * 100) /
        (strategyArea + cultureArea + structureArea + dataArea)
    const currentCultureState =
        (cultureArea * 100) /
        (strategyArea + cultureArea + structureArea + dataArea)
    const currentStructureState =
        (structureArea * 100) /
        (strategyArea + cultureArea + structureArea + dataArea)
    const currentDataState =
        (dataArea * 100) /
        (strategyArea + cultureArea + structureArea + dataArea)
    const currentStrategyStateGap =
        100 - (strategyArea * 100) / (bigCircleArea / 4)
    const currentCultureStateGap =
        100 - (cultureArea * 100) / (bigCircleArea / 4)
    const currentStructureStateGap =
        100 - (structureArea * 100) / (bigCircleArea / 4)
    const currentDataStateGap = 100 - (dataArea * 100) / (bigCircleArea / 4)

    return (
        <Box className={styles.reportPage}>
            <Box className={styles.reportPageContainer}>
                <ReportHeader date={now.toLocaleDateString()} name={QName} />
                <SectionHeader name={t('part2Title', { ns: 'report' })} />
                <BusinessOrientationSection
                    {...{
                        strategyArea,
                        cultureArea,
                        structureArea,
                        dataArea,
                    }}
                ></BusinessOrientationSection>
                <CSOTBPSection
                    {...{
                        Q2,
                        currentState,
                        currentStrategyState,
                        currentCultureState,
                        currentDataState,
                        currentStructureState,
                    }}
                ></CSOTBPSection>
                <PTMBPSection
                    {...{
                        currentStrategyStateGap,
                        currentCultureStateGap,
                        currentDataStateGap,
                        currentStructureStateGap,
                    }}
                ></PTMBPSection>
                <Footer />
            </Box>
        </Box>
    )
}
export const ReportPage3 = ({
    QName,
    Q1,
    Q2,
    Q3,
    Q4,
    Q5,
    Q6,
    Q7,
    Q8,
    Q9,
    Q10,
    Q11,
    Q12,
    Q13,
    Q14,
    Q15,
    Q16,
    QEvents,
    graphPath,
    smallCircleArea,
    bigCircleArea,
    strategyArea,
    cultureArea,
    structureArea,
    dataArea,
    vmvPosition,
    vmvSector,
}: ReportProps) => {
    const { t, i18n } = useTranslation()
    document.dir = i18n.dir()
    const styles = useReportStyles()
    const now = new Date()

    return (
        <Box className={styles.reportPage}>
            <Box className={styles.reportPageContainer}>
                <ReportHeader date={now.toLocaleDateString()} name={QName} />
                <SectionHeader name={t('part3Title', { ns: 'report' })} />
                <RecommendationsSection Q2={Q2}></RecommendationsSection>
                <SectionHeader name={t('part4Title', { ns: 'report' })} />
                <OriginalInputSection
                    {...{
                        QName,
                        Q1,
                        Q2,
                        Q3,
                        Q4,
                        Q5,
                        Q6,
                        Q7,
                        Q8,
                        Q9,
                        Q10,
                        Q11,
                        Q12,
                        Q13,
                        Q14,
                        Q15,
                        Q16,
                        QEvents,
                    }}
                ></OriginalInputSection>
                <Footer />
            </Box>
        </Box>
    )
}
