import * as React from 'react'
import Box from '@mui/material/Box'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useReportStyles } from './styles'

export type GraphAnalysisProps = {
    Q1: string
    Q3: string
    Q4: string
    vmvSector: string
    vmvPosition: string
    minPotential: number
    currentState: number
    currentStrategyState: number
    currentStrategyStateGap: number
    currentCultureState: number
    currentCultureStateGap: number
    currentDataState: number
    currentDataStateGap: number
    currentStructureState: number
    currentStructureStateGap: number
}

export const GraphAnalysis = ({
    Q1,
    Q3,
    Q4,
    vmvSector,
    vmvPosition,
    minPotential,
    currentState,
    currentStrategyState,
    currentStrategyStateGap,
    currentCultureState,
    currentCultureStateGap,
    currentDataState,
    currentDataStateGap,
    currentStructureState,
    currentStructureStateGap,
}: GraphAnalysisProps) => {
    const { t } = useTranslation()
    const styles = useReportStyles()
    return (
        <Box className={styles.generalContainer}>
            <Box className={styles.graphAnalysisTitle}>
                {t('graphAnalysis')}
            </Box>
            {Q1 === 'Yes' && (
                <Box>
                    {' '}
                    <Box className={styles.graphAnalysisSubtitle}>
                        {t('vmvLocation')}: {vmvSector}, {vmvPosition}{' '}
                        {t('ofCompanyBoundaries')}
                    </Box>
                    <Box className={styles.graphAnalysisSubtitle}>
                        {t('vmvAlignment')}:{' '}
                        {Q3 === 'ClearAndSupportive' && Q4 === 'VeryLikely'
                            ? t('Full')
                            : t('Partial')}
                    </Box>
                </Box>
            )}
            <Box className={styles.graphAnalysisSubtitle}>
                <Box>
                    {t('minimumPotential')}:{' '}
                    {minPotential ? <>{minPotential.toFixed(1)}%</> : <>N/A</>}
                </Box>
            </Box>
            <Box className={styles.graphAnalysisSubtitle}>
                <Box>
                    {t('currentState')}:{' '}
                    {currentState ? <>{currentState.toFixed(1)}%</> : <>N/A</>}
                </Box>
            </Box>

            <Grid container spacing={1} className={styles.graphAnalysisTable}>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                    <b>{t('currentStateBreakdown')}</b>
                </Grid>
                <Grid item xs={4}>
                    <b>{t('gapToMaximumPotential')}</b>
                </Grid>
                {/* Strategy */}
                <Grid item xs={4}>
                    <b>{t('strategy')}</b>
                </Grid>
                <Grid item xs={4}>
                    {currentStrategyState ? (
                        <>{currentStrategyState.toFixed(1)}%</>
                    ) : (
                        <>N/A</>
                    )}
                </Grid>
                <Grid item xs={4}>
                    {currentStrategyStateGap ? (
                        <>{currentStrategyStateGap.toFixed(1)}%</>
                    ) : (
                        <>N/A</>
                    )}
                </Grid>
                {/* Culture */}
                <Grid item xs={4}>
                    <b>{t('culture')}</b>
                </Grid>
                <Grid item xs={4}>
                    {currentCultureState ? (
                        <>{currentCultureState.toFixed(1)}%</>
                    ) : (
                        <>N/A</>
                    )}
                </Grid>
                <Grid item xs={4}>
                    {currentCultureStateGap ? (
                        <>{currentCultureStateGap.toFixed(1)}%</>
                    ) : (
                        <>N/A</>
                    )}
                </Grid>
                {/* Structure */}
                <Grid item xs={4}>
                    <b>{t('structure')}</b>
                </Grid>
                <Grid item xs={4}>
                    {currentStructureState ? (
                        <>{currentStructureState.toFixed(1)}%</>
                    ) : (
                        <>N/A</>
                    )}
                </Grid>
                <Grid item xs={4}>
                    {currentStructureStateGap ? (
                        <>{currentStructureStateGap.toFixed(1)}%</>
                    ) : (
                        <>N/A</>
                    )}
                </Grid>
                {/* DATA */}
                <Grid item xs={4}>
                    <b>{t('data')}</b>
                </Grid>
                <Grid item xs={4}>
                    {currentDataState ? (
                        <>{currentDataState.toFixed(1)}%</>
                    ) : (
                        <>N/A</>
                    )}
                </Grid>
                <Grid item xs={4}>
                    {currentDataStateGap ? (
                        <>{currentDataStateGap.toFixed(1)}%</>
                    ) : (
                        <>N/A</>
                    )}
                </Grid>
            </Grid>
        </Box>
    )
}
