import * as React from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { useReportStyles } from './styles'

export type BusinessOrientationSectionProps = {
    strategyArea: number
    cultureArea: number
    structureArea: number
    dataArea: number
}

export const BusinessOrientationSection = ({
    strategyArea,
    cultureArea,
    structureArea,
    dataArea,
}: BusinessOrientationSectionProps) => {
    const { t } = useTranslation()
    const styles = useReportStyles()
    const totalArea = strategyArea + cultureArea + structureArea + dataArea
    const transactionFuturePercentage =
        ((strategyArea + cultureArea) / totalArea) * 100
    const transactionPastPercentage = 100 - transactionFuturePercentage
    const transformationFuturePercentage =
        ((dataArea + cultureArea) / totalArea) * 100
    const transformationPastPercentage = 100 - transformationFuturePercentage
    return (
        <Box className={styles.sectionContainer}>
            <Box className={styles.sectionTitle}>
                {t('business_orientation.title', { ns: 'report' })}
            </Box>
            <Box className={styles.sectionTextContainer}>
                {transactionFuturePercentage > 75
                    ? t('business_orientation.transaction.75_plus_future', {
                          ns: 'report',
                      })
                    : transactionFuturePercentage > 55
                    ? t('business_orientation.transaction.55_75_future', {
                          ns: 'report',
                      })
                    : transactionPastPercentage <= 55
                    ? t('business_orientation.transaction.balanced', {
                          ns: 'report',
                      })
                    : transactionPastPercentage <= 75
                    ? t('business_orientation.transaction.55_75_past', {
                          ns: 'report',
                      })
                    : t('business_orientation.transaction.75_plus_past', {
                          ns: 'report',
                      })}
                <br />
                <br />
                {transformationFuturePercentage > 75
                    ? t('business_orientation.transformation.75_plus_future', {
                          ns: 'report',
                      })
                    : transformationFuturePercentage > 55
                    ? t('business_orientation.transformation.55_75_future', {
                          ns: 'report',
                      })
                    : transformationPastPercentage <= 55
                    ? t('business_orientation.transformation.balanced', {
                          ns: 'report',
                      })
                    : transformationPastPercentage <= 75
                    ? t('business_orientation.transformation.55_75_past', {
                          ns: 'report',
                      })
                    : t('business_orientation.transformation.75_plus_past', {
                          ns: 'report',
                      })}
            </Box>
        </Box>
    )
}
