import * as React from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

export type ReportHeaderProps = {
    date: string
    name: string
}

export const ReportHeader = ({ date, name }: ReportHeaderProps) => {
    const { t } = useTranslation()
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                alignSelf: 'center',
                marginBottom: '30px',
                marginTop: '30px',
            }}
        >
            <Box
                sx={{
                    fontSize: '80px',
                    width: '100%',
                    fontWeight: '700',
                    color: '#0054a3',
                }}
            >
                {t('title', { ns: 'report' })}
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    alignSelf: 'center',
                    fontSize: '50px',
                }}
            >
                <Box
                    sx={{
                        width: '16.6%',
                    }}
                ></Box>
                <Box
                    sx={{
                        width: '16.6%',
                        fontWeight: '400',
                        margin: '10px',
                    }}
                >
                    {t('companyName', { ns: 'report' })}
                </Box>
                <Box
                    sx={{
                        width: '16.6%',
                        fontWeight: '700',
                        margin: '30px',
                    }}
                >
                    {name}
                </Box>
                <Box
                    sx={{
                        width: '16.6%',
                        fontWeight: '400',
                        margin: '50px',
                    }}
                >
                    {t('reportDate', { ns: 'report' })}
                </Box>
                <Box
                    sx={{
                        width: '16.6%',
                        fontWeight: '700',
                        margin: '30px',
                    }}
                >
                    {date}
                </Box>
                <Box
                    sx={{
                        width: '16.6%',
                    }}
                ></Box>
            </Box>
        </Box>
    )
}
