import {
    FormControlLabel,
    FormLabel,
    Input,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
} from '@material-ui/core'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Collapse from '@mui/material/Collapse'
import axios from 'axios'
import { jsPDF } from 'jspdf'
import * as React from 'react'
import domtoimage from 'dom-to-image'
import { q2Answers, q3Answers, q4Answers } from '../Constants/questions'
import { CustomSlider } from './CustomSlider'
import { ReportPage1, ReportPage2, ReportPage3 } from './Report/Report'
import { useFormStyles } from './styles'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import ReactFlagsSelect from 'react-flags-select'
import emailjs from 'emailjs-com'
import ReactS3Client from 'react-aws-s3-typescript'
import { Buffer } from 'buffer'
const GRAPH_GENERATOR_ULR =
    'https://api.ocsdiagnostics.com/generate'

const s3config = {
    bucketName: 'ocs-diagnostics-reports',
    region: 'eu-west-3',
    accessKeyId: 'AKIA3J4VRK67D4A577EC',
    secretAccessKey: '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',
}

const isEmail = (email: string) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}
const getCountry = (lan: string) => {
    if (lan === 'he') return 'IL'
    if (lan === 'es') return 'ES'
    if (lan === 'fr') return 'FR'
    return 'US'
}

const getLanguage = (lan: string) => {
    if (lan === 'IL') return 'he'
    if (lan === 'ES') return 'es'
    if (lan === 'FR') return 'fr'
    return 'en'
}

export const Form = () => {
    const styles = useFormStyles()
    const { t, i18n } = useTranslation()
    document.body.dir = i18n.dir()
    const [Q1, setQ1] = React.useState('No' as string)
    const [Q2, setQ2] = React.useState(q2Answers[0].value as string)
    const [Q3, setQ3] = React.useState(q3Answers[0].value as string)
    const [Q4, setQ4] = React.useState(q4Answers[0].value as string)
    const [Q5, setQ5] = React.useState(5)
    const [Q6, setQ6] = React.useState(5)
    const [Q7, setQ7] = React.useState(5)
    const [Q8, setQ8] = React.useState(5)
    const [Q9, setQ9] = React.useState(5)
    const [Q10, setQ10] = React.useState(5)
    const [Q11, setQ11] = React.useState(5)
    const [Q12, setQ12] = React.useState(5)
    const [Q13, setQ13] = React.useState(5)
    const [Q14, setQ14] = React.useState(5)
    const [Q15, setQ15] = React.useState(5)
    const [Q16, setQ16] = React.useState(5)
    const [QEvents, setQEvents] = React.useState(1)
    const [QName, setQName] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)
    const [isSendingEmail, setIsSendingEmail] = React.useState(false)
    const [isGeneratingReport, setIsGeneratingReport] = React.useState(false)
    const [imageSrc, setImageSrc] = React.useState('')
    const [smallCircleArea, setSmallCircleArea] = React.useState(0)
    const [bigCircleArea, setBigCircleArea] = React.useState(0)
    const [strategyArea, setStrategyArea] = React.useState(0)
    const [cultureArea, setCultureArea] = React.useState(0)
    const [structureArea, setStructureArea] = React.useState(0)
    const [dataArea, setDataArea] = React.useState(0)
    const [vmvPosition, setVmvPosition] = React.useState('')
    const [vmvSector, setVmvSector] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [emailSent, setEmailSent] = React.useState(false)
    const [emailError, setEmailError] = React.useState(false)
    const [graphGenerated, setGraphGenerated]= React.useState(false)
    const graphRef = React.useRef(null)
    const emailSheetUrl =
        'https://sheet.best/api/sheets/3fea3177-8e8a-49d7-b71c-1af58cefbfd8'

    const saveEmailAddress = async () => {
        if (emailSent) {
            return
        }
        if (isEmail(email)) {
            setIsSendingEmail(true)
            setEmailError(false)
            axios.post(emailSheetUrl, { email }).then((result) => {
                console.log("email sending result status: ",result.status)
                if (result.status === 200) {
                    setEmailSent(true)
                    setIsSendingEmail(false)
                    //send feedback email
                }
                setIsSendingEmail(false)
            })
        } else {
            setEmailError(true)
        }
    }
    
    React.useEffect(() => {
        document.getElementById('year')!.innerHTML = new Date()
            .getFullYear()
            .toString()
    }, [])
    document.dir = 'rtl'

    const uploadFile = async (arrayBuffer: ArrayBuffer) => {
        const pdfBuffer = Buffer.from(new Uint8Array(arrayBuffer))
        var blob = new Blob([pdfBuffer], {
            type: 'application/pdf',
        })
        var file = new File([blob], `pdf`)
        const s3 = new ReactS3Client(s3config)
        try {
            const res = await s3.uploadFile(
                file,
                `${QName}-OcsReport-${Date.now()}`
            )

        } catch (exception) {
            console.log(exception)
        }
    }

    const onGenerateGraph = async () => {
        setIsLoading(true)
        setImageSrc('')
       console.log({QName,Q1,Q2,Q3,Q4,Q5,Q6,Q7,Q8,Q9,Q10,Q11,Q12,Q13,Q14,Q15,Q16,QEvents: Number(QEvents)})
        axios
            .post(GRAPH_GENERATOR_ULR,{QName,Q1,Q2,Q3,Q4,Q5,Q6,Q7,Q8,Q9,Q10,Q11,Q12,Q13,Q14,Q15,Q16,QEvents: Number(QEvents)})
            .then((res: { status: number; data: any }) => {
                setIsLoading(false)
                console.log("graph generation result status: ",res.status)
                if (res.status === 200) {
                    console.log("graph generation result data: ", res.data)
                    setImageSrc(res.data.graphFileName)
                    setBigCircleArea(res.data.bigCircleArea)
                    setSmallCircleArea(res.data.smallCircleArea)
                    setStrategyArea(res.data.strategyArea)
                    setCultureArea(res.data.cultureArea)
                    setStructureArea(res.data.structureArea)
                    setDataArea(res.data.dataArea)
                    setVmvPosition(res.data.vmvPosition)
                    setVmvSector(res.data.vmvSector)
                }
            })
            .catch((e: any) => {
                setIsLoading(false)
            })
    }


    const onGenerateReport = async () => {
        setIsGeneratingReport(true)
        var doc = new jsPDF('p', 'pt', 'a4')
        domtoimage
            .toPng(document.getElementById('pdfPage1')!)
            .then((data: string) => {
                const element = document.getElementById('pdfPage1')!
                domtoimage.toPng(element).then((imgDataPage1: string) => {
                    domtoimage
                        .toPng(document.getElementById('pdfPage2')!)
                        .then((imgDataPage2: string) => {
                            domtoimage
                                .toPng(document.getElementById('pdfPage3')!)
                                .then(async (imgDataPage3: string) => {
                                    var page1 = new Image()
                                    var page2 = new Image()
                                    var page3 = new Image()
                                    page1.src = imgDataPage1
                                    page2.src = imgDataPage2
                                    page3.src = imgDataPage3
                                    doc.addImage(
                                        page1,
                                        'PNG',
                                        0,
                                        0,
                                        595,
                                        841,
                                        '',
                                        'FAST'
                                    )
                                    doc.addPage()
                                    doc.addImage(
                                        page2,
                                        'PNG',
                                        0,
                                        0,
                                        595,
                                        841,
                                        '',
                                        'FAST'
                                    )
                                    doc.addPage()
                                    doc.addImage(
                                        page3,
                                        'PNG',
                                        0,
                                        0,
                                        595,
                                        841,
                                        '',
                                        'FAST'
                                    )
                                    doc.save(`${QName}-OcsReport`)
                                    //Upload to S3
                                    const arrayBuffer =
                                        doc.output('arraybuffer')
                                    doc.close()

                                    //uploadFile(arrayBuffer)

                                    setIsGeneratingReport(false)
                                    setGraphGenerated(true)
                                    emailjs.send(
                                        'service_pywd7in',
                                        'template_aij6vqq',
                                        { email: email },
                                        'pVpaOglPxlg_MEMdL'
                                    )
                                })
                        })
                })
            })
    }

    React.useEffect(() => {
        async function generateGraph() {
            await onGenerateGraph();
          }
        if(emailSent && !imageSrc){
             console.log("generating graph...")
             setIsLoading(true)
             generateGraph();
        }
    }, [emailSent])
    
    React.useEffect(() => {
        async function generateReport() {
            await onGenerateReport();
          }
          if(emailSent && imageSrc){
            setIsLoading(true)
             console.log("generating report...")
             generateReport();
             setIsLoading(false)
            }
        }, [imageSrc])

    const sendEmailAndGenerateReport = async ()=>{
        console.log("saving email addresses...")
        await saveEmailAddress(); 
       
    }

    const changeLanguageHandler = (code: string) => {
        const languageValue = getLanguage(code)
        i18next.changeLanguage(languageValue)
    }

    return (
        <Box className={styles.container}>
             <div className={styles.reportMask}> 
                {/* <div> */}
                <div className={styles.reportPageMask}>
                    <div id="pdfPage1">
                        <ReportPage1
                            QName={QName}
                            Q1={Q1}
                            Q2={Q2}
                            Q3={Q3}
                            Q4={Q4}
                            Q5={Q5}
                            Q6={Q6}
                            Q7={Q7}
                            Q8={Q8}
                            Q9={Q9}
                            Q10={Q10}
                            Q11={Q11}
                            Q12={Q12}
                            Q13={Q13}
                            Q14={Q14}
                            Q15={Q15}
                            Q16={Q16}
                            QEvents={QEvents}
                            graphPath={imageSrc}
                            smallCircleArea={smallCircleArea}
                            bigCircleArea={bigCircleArea}
                            strategyArea={strategyArea}
                            cultureArea={cultureArea}
                            structureArea={structureArea}
                            dataArea={dataArea}
                            vmvPosition={vmvPosition}
                            vmvSector={vmvSector}
                        />
                    </div>
                </div>
                <div className={styles.reportPageMask}>
                    <div id="pdfPage2">
                        <ReportPage2
                            QName={QName}
                            Q1={Q1}
                            Q2={Q2}
                            Q3={Q3}
                            Q4={Q4}
                            Q5={Q5}
                            Q6={Q6}
                            Q7={Q7}
                            Q8={Q8}
                            Q9={Q9}
                            Q10={Q10}
                            Q11={Q11}
                            Q12={Q12}
                            Q13={Q13}
                            Q14={Q14}
                            Q15={Q15}
                            Q16={Q16}
                            QEvents={QEvents}
                            graphPath={imageSrc}
                            smallCircleArea={smallCircleArea}
                            bigCircleArea={bigCircleArea}
                            strategyArea={strategyArea}
                            cultureArea={cultureArea}
                            structureArea={structureArea}
                            dataArea={dataArea}
                            vmvPosition={vmvPosition}
                            vmvSector={vmvSector}
                        />
                    </div>
                </div>
                <div className={styles.reportPageMask}>
                    <div id="pdfPage3">
                        <ReportPage3
                            QName={QName}
                            Q1={Q1}
                            Q2={Q2}
                            Q3={Q3}
                            Q4={Q4}
                            Q5={Q5}
                            Q6={Q6}
                            Q7={Q7}
                            Q8={Q8}
                            Q9={Q9}
                            Q10={Q10}
                            Q11={Q11}
                            Q12={Q12}
                            Q13={Q13}
                            Q14={Q14}
                            Q15={Q15}
                            Q16={Q16}
                            QEvents={QEvents}
                            graphPath={imageSrc}
                            smallCircleArea={smallCircleArea}
                            bigCircleArea={bigCircleArea}
                            strategyArea={strategyArea}
                            cultureArea={cultureArea}
                            structureArea={structureArea}
                            dataArea={dataArea}
                            vmvPosition={vmvPosition}
                            vmvSector={vmvSector}
                        />
                    </div>
                </div>
             </div> 
            <Box className={styles.header}>
                <Box className={styles.headerContainer}>
                    <h2>{t('Title')}</h2>
                    <ReactFlagsSelect
                        selected={getCountry(i18next.language)}
                        countries={['US', 'ES', 'IL', 'FR']}
                        customLabels={{
                            US: 'English',
                            ES: 'Español',
                            IL: 'עִברִית',
                            FR: 'Français',
                        }}
                        fullWidth={false}
                        onSelect={(code) => changeLanguageHandler(code)}
                    />
                    <h3>
                        <b>{t('Subtitle1')}</b>
                    </h3>{' '}
                    <p>{t('Description')}</p>{' '}
                    <a
                        href="https://my.visme.co/view/310p7ozq-m3x58kew18nm5krp"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <Button variant="outlined" id="white-paper-button">
                            {t('ButtonWhitePaper')}
                        </Button>
                    </a>
                    <br />
                    <br />
                    <Box>
                        {' '}
                        <b>{t('Subtitle2')}</b>
                        <Box className={styles.callToActionButtonsContainer}>
                            <Button
                                variant="contained"
                                className={styles.callToActionButton}
                                sx={{ marginRight: '10px' }}
                                onClick={() => {
                                    window.open(
                                        'mailto:elena@purplesuncorp.com?subject=Workshop'
                                    )
                                }}
                                id="workshop-button"
                            >
                                {t('ButtonJoinWorkshop')}
                            </Button>
                            <Button
                                variant="outlined"
                                className={styles.callToActionButton}
                                onClick={() => {
                                    window.open(
                                        'mailto:elena@purplesuncorp.com?subject=Workshop'
                                    )
                                }}
                                id="book-consulting-button"
                            >
                                {t('ButtonBookConsulting')}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className={styles.formContainer}>
                <Box id="boxqName" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('CompanyName')}
                    </FormLabel>
                    <TextField
                        id="qName"
                        value={QName}
                        onChange={(e: any) => setQName(e.target.value)}
                    />
                </Box>
                <Box id="boxq1" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question1')}
                    </FormLabel>
                    <Select
                        id="q1"
                        value={Q1}
                        onChange={(event: any) => {
                            setQ1(event.target.value as string)
                        }}
                        className={styles.q1Select}
                    >
                        <MenuItem value="No">{t('No')}</MenuItem>
                        <MenuItem value="Yes">{t('Yes')}</MenuItem>
                    </Select>
                </Box>
                <Collapse in={Q1 === 'Yes'}>
                    <Box>
                        <Box id="boxq2" className={styles.question}>
                            <FormLabel className={styles.label}>
                                {t('question2')}
                            </FormLabel>
                            <RadioGroup
                                id="q2RadioGroup"
                                value={Q2}
                                onChange={(event: any) => {
                                    setQ2(event.target.value as string)
                                }}
                            >
                                {q2Answers.map((answer, index) => {
                                    return (
                                        <FormControlLabel
                                            value={answer.value}
                                            control={<Radio color="primary" />}
                                            label={t(answer.value)}
                                            key={`q2${index}`}
                                        />
                                    )
                                })}
                            </RadioGroup>
                        </Box>
                        <Box id="boxq3" className={styles.question}>
                            <FormLabel className={styles.label}>
                                {t('question3')}
                            </FormLabel>
                            <RadioGroup
                                id="q3RadioGroup"
                                value={Q3}
                                onChange={(event: any) => {
                                    setQ3(event.target.value as string)
                                }}
                            >
                                {q3Answers.map((answer, index) => {
                                    return (
                                        <FormControlLabel
                                            value={answer.value}
                                            control={<Radio color="primary" />}
                                            label={t(answer.value)}
                                            key={`q3${index}`}
                                        />
                                    )
                                })}
                            </RadioGroup>
                        </Box>
                        <Box id="boxq4" className={styles.question}>
                            <FormLabel className={styles.label}>
                                {t('question4')}
                            </FormLabel>
                            <RadioGroup
                                id="q4RadioGroup"
                                value={Q4}
                                onChange={(event: any) => {
                                    setQ4(event.target.value as string)
                                }}
                            >
                                {q4Answers.map((answer, index) => {
                                    return (
                                        <FormControlLabel
                                            value={answer.value}
                                            control={<Radio color="primary" />}
                                            label={t(answer.value)}
                                            key={`q4${index}`}
                                        />
                                    )
                                })}
                            </RadioGroup>
                        </Box>

                        {Q2 === q2Answers[1].value && (
                            <Box id="qEvents" className={styles.question}>
                                <FormLabel className={styles.label}>
                                    {t('questionEvents')}
                                </FormLabel>
                                <Input
                                    type="number"
                                    id="qEventsInput"
                                    value={QEvents}
                                    onChange={(event: any) => {
                                        const value = event.target
                                            .value as unknown as number
                                        if (value > 0) setQEvents(value)
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                </Collapse>

                <Box id="boxq5" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question5')}
                    </FormLabel>
                    <CustomSlider id="q5" value={Q5} setValue={setQ5} />
                </Box>
                <Box id="boxq6" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question6')}
                    </FormLabel>
                    <CustomSlider id="q6" value={Q6} setValue={setQ6} />
                </Box>
                <Box id="boxq7" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question7')}
                    </FormLabel>
                    <CustomSlider id="q7" value={Q7} setValue={setQ7} />
                </Box>
                <Box id="boxq8" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question8')}
                    </FormLabel>
                    <CustomSlider id="q8" value={Q8} setValue={setQ8} />
                </Box>
                <Box id="boxq9" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question9')}
                    </FormLabel>
                    <CustomSlider id="q9" value={Q9} setValue={setQ9} />
                </Box>
                <Box id="boxq10" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question10')}
                    </FormLabel>
                    <CustomSlider id="q10" value={Q10} setValue={setQ10} />
                </Box>
                <Box id="boxq11" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question11')}
                    </FormLabel>
                    <CustomSlider id="q11" value={Q11} setValue={setQ11} />
                </Box>
                <Box id="boxq12" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question12')}
                    </FormLabel>
                    <CustomSlider id="q12" value={Q12} setValue={setQ12} />
                </Box>
                <Box id="boxq13" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question13')}
                    </FormLabel>
                    <CustomSlider id="q13" value={Q13} setValue={setQ13} />
                </Box>
                <Box id="boxq14" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question14')}
                    </FormLabel>
                    <CustomSlider id="q14" value={Q14} setValue={setQ14} />
                </Box>
                <Box id="boxq15" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question15')}
                    </FormLabel>
                    <CustomSlider id="q15" value={Q15} setValue={setQ15} />
                </Box>
                <Box id="boxq16" className={styles.question}>
                    <FormLabel className={styles.label}>
                        {t('question16')}
                    </FormLabel>
                    <CustomSlider id="q16" value={Q16} setValue={setQ16} />
                </Box>

                <Box className={styles.submit}>
                    {!emailSent && (
                        <div>
                            <FormLabel className={styles.label}>
                                {t('EmailLabel')}
                            </FormLabel>

                            <br />
                            <TextField
                                id="qEmail"
                                type="email"
                                value={email}
                                onChange={(e: any) => {
                                    setEmail(e.target.value)
                                }}
                                error={emailError}
                                helperText={emailError && t('IncorrectEmail')}
                                className={styles.sendEmailTextField}
                            />
                        </div>
                    )}
                    {!graphGenerated ?( !(isSendingEmail || isGeneratingReport || isLoading) && <Button
                            disabled={!isEmail(email)}
                            variant="outlined"
                            className={styles.sendEmailButton}
                            onClick={sendEmailAndGenerateReport}
                            id="download_report"
                        >
                            {t('Generate')} {t('report')}
                        </Button>)
                        :
                        <Box>{t('thankYouAndSurvey')}</Box>
                    }
                    {(isSendingEmail || isGeneratingReport || isLoading) && (
                        <Box className={styles.loader}>
                        {isGeneratingReport && <Box> {t('generatingReport')} </Box>}
                        {isLoading && <Box> {t('processing')} </Box>}
                            <CircularProgress style={{alignSelf:'center', marginTop:'35px'}}/>
                        </Box>
                    )}
                     {!graphGenerated && imageSrc && <img src={`https://ocs-diagnostics-graphs.s3.eu-west-3.amazonaws.com/${imageSrc}`} alt='graph' style={{width:'100%'}}/>}
                </Box>
              
                <div ref={graphRef} />
            </Box>

            <Box className={styles.footerContainer}>
                <Box>
                    (C)<span id="year"></span> {t('AllRightsReserved')}
                </Box>
                <Box>
                    {t('builtBy')}{' '}
                    <a
                        href="https://www.linkedin.com/in/andrei-tudorica-661a22b9/"
                        className={styles.footerName}
                    >
                        Andrei Tudorica
                    </a>
                </Box>
            </Box>
        </Box>
    )
}
